import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "values-and-expressions"
    }}>{`Values and Expressions`}</h1>
    <h2 {...{
      "id": "values"
    }}>{`Values`}</h2>
    <p>{`In programming, we regularly deal with `}<em parentName="p">{`values`}</em>{`. These so-called `}<strong parentName="p">{`values`}</strong>{` are just the "things" that we can access inside of our programs. For example, a number would be considered a value. A piece of text "Hello world!" would also be considered a value.`}</p>
    <p>{`It wouldn't make much sense to do arithmetic (like adding or subtracting) without numbers. In a similar way, it wouldn't make much sense to program without values. These `}<em parentName="p">{`values`}</em>{` hold the information that we want to work with. Let's look at an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=values-hello-world",
        "live": "true",
        "id": "values-hello-world"
      }}>{`$text("Hello world!", 25, 30);
`}</code></pre>
    <p>{`In this example, we've got a handful of values!`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`"Hello world!"`}</inlineCode>{` is a value. We generally call text values like this `}<em parentName="li">{`string`}</em>{` values, because they are "strings" of characters. (Think words and sentences.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`25`}</inlineCode>{` and `}<inlineCode parentName="li">{`30`}</inlineCode>{` are both values that you should recognize. They're just numbers.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$text`}</inlineCode>{` is technically a value too! But it's a `}<em parentName="li">{`function`}</em>{`, and we'll talk more about those in a later chapter.`}</li>
    </ul>
    <p>{`Values themselves are `}<em parentName="p">{`incredibly`}</em>{` useful to us, but not all that interesting by themselves. However, they become quite a bit more interesting once we start combining them!`}</p>
    <h2 {...{
      "id": "expressions"
    }}>{`Expressions`}</h2>
    <p><em parentName="p">{`Values`}</em>{` are very plain and they don't ever change. For example, `}<inlineCode parentName="p">{`5`}</inlineCode>{` is always `}<inlineCode parentName="p">{`5`}</inlineCode>{` 🤷‍♂️. However, once we start `}<em parentName="p">{`putting values together`}</em>{`, things start to get a little more interesting! For example, adding numbers is a bit more useful than just looking at the number `}<inlineCode parentName="p">{`5`}</inlineCode>{`!`}</p>
    <p>{`In programming, we combine values together to create `}<strong parentName="p">{`expressions`}</strong>{`. Here are some examples of expressions:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`5 + 3`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`(3 + 9) / 4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"Hello " + "world!"`}</inlineCode></li>
    </ul>
    <p>{`Expressions are just combinations of values that evaluate to a `}<em parentName="p">{`value`}</em>{`. This is important: `}<strong parentName="p">{`every expression can be evaluated to a single value`}</strong>{`. Let's revisit our previous expressions in a code editor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=values-expressions",
        "live": "true",
        "id": "values-expressions"
      }}>{`$log(5 + 3);
$log((3 + 9) / 4);
$log("Hello " + "world!");
`}</code></pre>
    <p>{`Notice that, in the our log, each of our expressions evaluates to a single value!`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`5 + 3`}</inlineCode>{` has a value of `}<inlineCode parentName="li">{`8`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`(3 + 9) / 4`}</inlineCode>{` has a value of `}<inlineCode parentName="li">{`3`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`"Hello " + "world!"`}</inlineCode>{` has a value of `}<inlineCode parentName="li">{`"Hello world!"`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "operators"
    }}>{`Operators`}</h2>
    <p>{`In the expression `}<inlineCode parentName="p">{`5 + 3`}</inlineCode>{` we combined two values (`}<inlineCode parentName="p">{`5`}</inlineCode>{` and `}<inlineCode parentName="p">{`3`}</inlineCode>{`) to create a different value (`}<inlineCode parentName="p">{`8`}</inlineCode>{`). In this case, we used `}<em parentName="p">{`addition`}</em>{` to combine these two values. We call addition an `}<em parentName="p">{`operator`}</em>{`.`}</p>
    <p><strong parentName="p">{`Operators`}</strong>{` are tools for taking one or two values and turning them into a new value! You're likely familiar with some math-related operators, such as addition, subtraction, and multiplication. But there are plenty of operators for working on different `}<em parentName="p">{`types`}</em>{` of values, like strings (or words) and lists.`}</p>
    <p>{`Operators are an important part of creating expressions, and we'll be using them regularly throughout this book! So let's take a look at one last example. Try to identify all of the operators you see (things that take one or two values and return a new one). Don't worry if you don't know what they all do.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=values-operators",
        "live": "true",
        "id": "values-operators"
      }}>{`$log(72 * 15);
$log(8 % 3);
$log(-32);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      